import { Text } from "@asayinc/component-library";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function OptOutCheckbox() {
  const { control } = useFormContext();
  const label = (
    <Stack sx={{ mt: 1.5, ml: 1 }}>
      <Text variant="subtitle1" sx={{ width: "100%", mb: 2 }}>
        {"Opt Out of Class Action"}
      </Text>
      <Text variant="body2">{"Select to opt out of the class action."}</Text>
    </Stack>
  );

  return (
    <Box
      sx={{
        py: 6,
        pt: 4,
        borderBottom: "1px solid #E0E0E0",
        flexGrow: 1,
        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={"optOutCheckboxValue"}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                sx={{ p: 2, ml: 2 }}
                size="small"
                checked={field.value}
              />
            )}
          />
        }
        sx={{ width: "100%", alignItems: "flex-start" }}
      />
    </Box>
  );
}
