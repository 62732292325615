import { ClassActionOptOutCommunication } from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";

export function getClassActionOptOutMainEventDescription(
  communication: ClassActionOptOutCommunication
) {
  const { issuerName } = communication.security;
  const { recordDateStart, recordDateEnd } = communication.classActionEvent;

  const formattedRecordDateStart = formatDate({
    date: recordDateStart,
    timezone: undefined,
  });

  const formattedRecordDateEnd = formatDate({
    date: recordDateEnd,
    timezone: undefined,
  });

  return `You are receiving this notification because a class action has been filed against ${issuerName} and you owned shares of ${issuerName} between ${formattedRecordDateStart} and ${formattedRecordDateEnd}.`;
}

export function getClassActionOptOutSecondaryEventDescription() {
  return "The class action documents cover the details about the class action. You can opt out of the class action.";
}
