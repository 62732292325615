import { LoadingSkeleton, SayIcon, Text } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { ClassActionOptOutCommunication } from "src/types";
import { formatDate } from "src/utils/formatDate";

export function OptOutSuccess() {
  const theme = useTheme();
  const { brokerId, communicationId } = useParams() as {
    brokerId: string;
    communicationId: string;
  };
  const { data: communication, isLoading } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  if (!communication) {
    return null;
  }

  const optOutCommunication = communication as ClassActionOptOutCommunication;
  const { security, classActionEvent } = optOutCommunication;
  const { issuerName } = security;
  const { optOutDeadline } = classActionEvent;

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", my: 4 }}>
        <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
        <LoadingSkeleton />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 6,
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        mb: 6,
        mt: 2,
        width: "100%",
      }}
    >
      <Stack alignItems="center">
        <Box>
          <SayIcon
            name="HowToRegOutline"
            size="48px"
            color={theme.palette.l4.main}
          />
        </Box>
        <Text
          variant="h5"
          sx={{ mt: 8, color: theme.palette.l4.main }}
          data-testid="attend-complete-title"
        >
          Settings updated successfully!
        </Text>

        <Text
          variant="body2"
          sx={{ mt: 6 }}
          data-testid="attend-complete-description"
        >
          {`You've successsfully submitted your opt-out preferences for the ${issuerName} class action. You may change your opt-out preferences for this class action at any time before the opt out deadline of ${formatDate(
            {
              date: optOutDeadline,
            }
          )}`}
        </Text>
      </Stack>
    </Box>
  );
}
