import { LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";

import { Communication } from "src/types";
import { ResourceRow } from "../ResourceRow";

interface IShareholderResourcesProps {
  communication: Communication | undefined;
  isLoading: boolean;
  isProxyCommunication: boolean | undefined;
}

export function ShareholderResources({
  communication,
  isLoading,
  isProxyCommunication,
}: IShareholderResourcesProps) {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        mb: 8,
        p: 6,
        [theme.breakpoints.down("sm")]: {
          mb: isProxyCommunication ? 4 : 0,
        },
      }}
      data-testid="sidebar-card"
    >
      <Text
        variant="subtitle2"
        emphasis="high"
        sx={{ mb: 3, color: theme.palette.l4.main }}
        loadingProps={{ sx: { minWidth: "2" } }}
      >
        {isLoading ? undefined : "Shareholder Resources"}
      </Text>
      {isLoading ? (
        <Box maxWidth="600px" minWidth="240px">
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Text variant="body2" sx={{ mb: 4 }}>
          {
            "The documents provide detailed information about the class action and can help you decide whether to opt out or not."
          }
        </Text>
      )}
      {isLoading ? (
        <Box>
          <Box sx={{ display: "flex", my: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
        </Box>
      ) : (
        <>
          {communication?.documents.map((attachment, index) => {
            return (
              <ResourceRow
                key={`management-resource-${index}`}
                attachment={attachment}
              />
            );
          })}
        </>
      )}
    </Box>
  );
}
