import { Box, Stack } from "@mui/material";
import { ButtonV2, LoadingSkeleton, Text } from "@asayinc/component-library";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  useCommunicationWithTrackingQuery,
  useSubmitOptOutMutation,
  useDeleteOptOutMutation,
} from "src/store/warrenG/communication";
import { ClassActionOptOutCommunication } from "src/types";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useEffect } from "react";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { useTrackingParams } from "src/hooks/useTrackingParams";
import { isPastDeadline } from "./utils";
import { OptOutCheckbox } from "../OptOutCheckbox/OptOutCheckbox";

export function OptOutForm() {
  const navigate = useNavigate();
  const trackingParams = useTrackingParams();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const {
    isLoading,
    data: communication,
    refetch,
  } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  const comm = communication as ClassActionOptOutCommunication;
  const [submitOptOut, { isLoading: isSubmitting }] = useSubmitOptOutMutation();
  const [deleteOptOut, { isLoading: isDeleting }] = useDeleteOptOutMutation();

  type OptOutFormValues = {
    optOutCheckboxValue: boolean;
  };

  const form = useForm<OptOutFormValues>({
    defaultValues: {
      optOutCheckboxValue: false,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = form;

  useEffect(() => {
    if (communication) {
      reset({ optOutCheckboxValue: comm.optOut ? true : false });
    }
  }, [communication, reset, comm]);

  if (isLoading) {
    return (
      <Box
        sx={{
          p: 6,
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", my: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
      </Box>
    );
  }

  const { classActionEvent } = communication as ClassActionOptOutCommunication;
  const { optOutDeadline } = classActionEvent;

  const pastDeadline = isPastDeadline(optOutDeadline);

  const onSubmit = async (formData: FieldValues) => {
    const { optOutCheckboxValue } = formData;

    if (optOutCheckboxValue === true) {
      try {
        await submitOptOut({
          brokerId,
          communicationId,
          ...trackingParams,
        });

        await refetch();

        track({
          name: TrackEventType.OptOutDetailsCreateOptOut,
          brokerId,
          communicationId,
          customerId: communication?.customer.id,
        });

        navigate(
          `/brokers/${brokerId}/opt-out/${communicationId}/opt-out-choice-confirmation` +
            location.search
        );
      } catch (error) {
        console.log(error);
      }
    } else if (optOutCheckboxValue === false) {
      try {
        await deleteOptOut({
          brokerId,
          communicationId,
          ...trackingParams,
        });

        await refetch();

        track({
          name: TrackEventType.OptOutDetailsDeleteOptOut,
          brokerId,
          communicationId,
          customerId: communication?.customer.id,
        });

        navigate(
          `/brokers/${brokerId}/opt-out/${communicationId}/opt-out-choice-confirmation` +
            location.search
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Box
        width="100%"
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          mb: 8,
          p: 6,
        }}
      >
        <FormProvider {...form}>
          <Text variant="body1" sx={{ mb: 5 }}>
            Informational blurb about opt out settings section.
          </Text>
          <form id="opt-out-form" onSubmit={handleSubmit(onSubmit)}>
            <OptOutCheckbox />
          </form>
        </FormProvider>
        <Stack
          sx={{
            position: "sticky",
            bottom: 0,
            right: 0,
          }}
        >
          <ButtonV2
            variant="primary"
            sx={{ mb: 2 }}
            disabled={!isDirty || pastDeadline}
            loading={isSubmitting || isDeleting}
            form="opt-out-form"
            type="submit"
          >
            Submit changes
          </ButtonV2>
        </Stack>
      </Box>
    </>
  );
}
